import React, { component } from 'react';

import Happy_Customer from './happy-customer';




class About_Us extends React.Component {
  render() {

    return (
      <>
        <style>
          {`
                    .row > * {
                      flex-shrink: 1;
                    }
                                                        
                     `}
        </style>

        {/* <!--banner start--> */}
        <div className="hm_bread_wrapper">
          <div className="container">
            <div className="row" style={{ marginTop: "58px", marginBottom: "-79px" }}>
              <div className="col-lg-12 col-md-12">
                <h1>about</h1>
                {/* <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">about</li>
                        </ol> */}
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        {/* <!--About section start--> */}

        <div className="hm_about_wrapper">
          <div className='container-fluid mw'>
            <div className='row '>
              <div className='text col-lg-5 col-md-6'>

                <h1 className="hm_heading text-color" style={{ color: "#484848" }}><span className="hm_color">About </span>Us</h1>
                <br />

                <p className='para'>Bee You Exports is a Lucknow-based enterprise dealing in the manufacturing and export of hand-embroidered Zardozi. The company has an elaborated network of skilled artisans at its disposal, who deploy traditional knitting methods and state-of-the-art techniques to create vibrant, intricate patterns on the most exquisite fabrics available. The end result is the finest craftsmanship money can buy.</p>
              </div>
              <div className='our-vision-container col-lg-5 col-md-5 img-fluid'>
              </div>
            </div>
          </div><br /><br />
          <div className="container mw">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="hm_about_img about-img">
                  <img src="assets/images/buexports/uploads/15.1.jpg" alt="handmade-craft-about" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="hm_about_data">
                  <h1><span className="hm_color">Our</span> Vision</h1>
                  <p>Bee You aims to elevate and preserve the age-old art of hand-embroidered Zardozi. Consequently, we look to promote local artisans by offering them a global showcase. Achieving this entails combining traditional embroidery skills with avant-garde styles and textures. In turn, our consumers get to experience the magnificence of this beautiful craft.</p>

                  {/* <a href="#" className="hm_btn">read more</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Choose section start--> */}
        {/* <div className="hm_choose_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        
                    </div>
                 <div className="col-lg-8 col-md-12 pull-right padding_left">
                        <div className="hm_choose_data">
                            <h1 className="hm_heading text-left"><span className="hm_color">why </span>Choose Us</h1>
                            <div className="hm_choose_list">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
       <h4>Respecting Your Time</h4>
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
       <h4>Latest In Technology</h4>
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      <h4>Professional Staff</h4>
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
     <h4>Free Home Dilevery</h4>
      </button>
    </h2>
    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      <h4>High Quality</h4>
      </button>
    </h2>
    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
</div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        {/* <!--happy customer sevtion start--> */}
        <Happy_Customer />
        {/* <!--our partners section start--> */}
        {/* <div className="hm_partners_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <h1 className="hm_heading">Our clients</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="hm_partners_slider">
                        <Carousel>
                     <Carousel.Item>
                                    <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                     </Carousel.Item>
                      <Carousel.Item>
                                   <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                       </Carousel.Item>
 
                      </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      </>
    )
  }

}
export default About_Us