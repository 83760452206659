import React from "react";
import Happy_Customer from "./happy-customer";

class Contact extends React.Component {
    render() {
        return (


            <>

                <style>
                    {`
				#submitform{
                    background: transparent;
                }
                #submitform:hover{
                    background: #efefef;
                }
						  
            `}
                </style>

                <div className="hm_bread_wrapper">
                    <div className="container">
                        <div className="row" style={{ marginTop: "58px" }}>
                            <div className="col-lg-12 col-md-12">
                                <h1>contact</h1>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--About section start--> */}
                <div className="contact_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="contact_box">
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <h2>email</h2>
                                    <p><a href="mailto:beeyouexports@gmail.com">beeyouexports@gmail.com</a></p>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="contact_box">
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <h2>phone</h2>
                                    <p>+91 522 402 8673</p>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="contact_box">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                    <h2>address</h2>
                                    <p>3/91, Vinamra Khand, Gomti Nagar, Lucknow, Uttar Pradesh, India 226010
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div class="container mt-5" >
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <iframe id="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14237.097697101093!2d81.02839850099825!3d26.86302094258798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x938f3bef15a97255!2sBee%20you%20exports!5e0!3m2!1sen!2sin!4v1650967586040!5m2!1sen!2sin" width="100%" height="400" allowfullscreen="" loading="lazy" class="border-3"></iframe>

                            </div>
                        </div>
                    </div>

                </div>
                {/* <!--contact form wrapper--> */}
                <div className="contact_form_wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <div className="col-lg-6 col-md-6 padding_left padding_right">
                        <div className="contact_map">
                            <div id="contact_map"> </div>
                        </div>
                    </div> */}
                            <div className="col-lg-10 col-md-10 padding_right padding_left form_head">
                                <div className="hm_form">
                                    <div className="col-lg-10 col-md-12">
                                        <h1><i class="fa fa-paper-plane" aria-hidden="true"></i> <span style={{ fontSize: "33px" }}>send a message</span>  </h1>
                                        <form className="form-inline" name="contactForm" id="contactForm">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control" id="name" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <input type="email" name="email" className="form-control" id="email" placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" name="phone" className="form-control" id="number" placeholder="Phone" />
                                            </div>
                                            <div className="form-group">
                                                <input type="text" name="subject" className="form-control" id="subject" placeholder="Subject" />
                                            </div>
                                            <div className="form-group textarea">
                                                <textarea className="form-control" name="message" rows="3" id="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="con_btn">
                                                <input type="submit" id="submitform" className="hm_btn submit_frm" style={{ borderRadius: "0px 0px 0px 0px" }} value="submit" />
                                            </div>
                                            <p id="err_msg"></p>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!--happy customer sevtion start--> */}

                <Happy_Customer />



            </>
        )
    }

}
export default Contact