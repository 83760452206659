import React, { component } from 'react';

class Footer extends React.Component {
    render() {

        return (
            <>
                <style>
                    {`
                    
                     `}
                </style>
                {/* <!--footer start--> */}
                <div className="hm_footer_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="hm_footer_box fml">
                                    <a className="navbar-brand text-white foot" href="/"><img src="assets/images/logo/2.png" alt="logo" height={90} width={100}/>Bee You <span>Exports</span></a>
                                    {/* <img src="assets/images/logo.png" alt="handmade-craft-logo" className="img-fluid"/> */}
                                    <p className="footer_about_para">Bee You Exports is a Lucknow-based enterprise dealing in the manufacturing and export of hand-embroidered Zardozi.</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-12 col-sm-12">
                                <div className="hm_footer_box">
                                    <h3 className="footer_heading">Help</h3>
                                    <ul>
                                        {/* <li><a href="#">Find Your Beer</a></li> */}
                                        <li><a href="/our-experts">Our Expertise</a></li>
                                        <li><a href="/contact">Contact Us</a></li>
                                        {/* <li><a href="#">Recent Orders</a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12">
                                <div className="hm_footer_box">
                                    <h3 className="footer_heading">links</h3>
                                    <ul>
                                        <li><a href="/">home</a></li>
                                        <li><a href="/about-us">about us</a></li>
                                        {/* <li><a href="/services">services</a></li> */}
                                        <li><a href="/products">products</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12">
                                <div className="hm_footer_box hm_footer_contact">
                                    <h3 className="footer_heading">Our Information</h3>
                                    <ul>
                                        <li>
                                            <div className="hm_footer_information">
                                                <span className="footer_icon"><i className="fa fa-map-marker" aria-hidden="true"></i> </span>
                                                <p className="foo_con_para">3/91, Vinamra Khand,Gomti<br /> Nagar, Lucknow,<br /> Uttar Pradesh, India 226010
                                                </p>
                                            </div>

                                        </li>
                                        <li>
                                            <div className="hm_footer_information"><span className="footer_icon"><i className="fa fa-phone" aria-hidden="true"></i> </span>
                                                <p className="foo_con_para">+91 522 402 8673</p></div>

                                        </li>
                                        <li>
                                            <div className="hm_footer_information">
                                                <a href="mailto:beeyouexports@gmail.com"><span className="footer_icon"><i className="fa fa-envelope" aria-hidden="true"></i> </span><p className="foo_con_para gm">beeyouexports@gmail.com</p></a>
                                            </div>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--bottom footer start--> */}
                <div className="hm_bottom_footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <p>Copyrights &copy; 2022 All Rights Reserved by BeeYouExports</p>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <ul className="footer_so_icons">
                                    <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
                                    <li><a href="#"><i className="fa fa-pinterest-p" aria-hidden="true"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--popup login form--> */}
                <div className="hm_login_form_overlay">
                    <div className="hm_login_form">
                        <div className="hm_login_header">
                            <h1>login form</h1>
                            <span className="login_close"><i className="fa fa-times" aria-hidden="true"></i></span>
                        </div>
                        <div className="login_fields">
                            <form>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="name" placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" id="pass" placeholder="Password" />
                                </div>
                                <div className="form_btn">
                                    <a href="#" className="hm_btn"> login</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <!--popup signup form--> */}
                <div className="hm_signup_form_overlay">
                    <div className="hm_signup_form">
                        <div className="hm_login_header">
                            <h1>signup form</h1>
                            <span className="login_close"><i className="fa fa-times" aria-hidden="true"></i></span>
                        </div>
                        <div className="login_fields">
                            <form>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="uname" placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="email" placeholder="Email" />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" id="password" placeholder="Password" />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" id="re-password" placeholder="Re-type Password" />
                                </div>
                                <div className="form_btn">
                                    <a href="#" className="hm_btn"> register now</a>
                                    <div className="register_info">already have an account? <a href="javascript:;">login</a></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>



            </>
        )
    }

}
export default Footer