import React, { component } from 'react';


class Header extends React.Component {


  render() {



    return (
      <>
        {/* <style>
          {`
               
                    
                    `}
        </style> */}

        {/* <!-- preloader   --> */}

        <div className="loader">
          <div className="loader-inner ball-scale-ripple-multiple">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <nav className="navbar fixed-top navbar-expand-lg navbar-dark p-md-3">
          <div className="container">
            <a className="navbar-brand" href="/"><img src="assets/images/logo/2.png" alt="logo" height={90} width={100}  className="logo"/>Bee You <span class="brand">Exports</span></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation" style={{ color: "#c64027" }}>
              <span className="navbar-toggler-icon" ><i class="fa fa-bars" aria-hidden="true" style={{ fontSize: "26px" }}></i></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <div className="mx-auto"></div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className={window.location.href.split('/')[3] == "" ? "nav-link active" : "nav-link"} href="/" >Home</a>
                </li>

                <li className="nav-item">
                  <a className={window.location.href.split('/')[3] == "about-us" ? "nav-link active" : "nav-link"} href="/about-us" onClick={this.handleClick}>About Us</a>
                </li>
                <li className="nav-item">
                  <a className={window.location.href.split('/')[3] == "products" ? "nav-link active" : "nav-link"} href="/products">Products</a>
                </li>
                <li className="nav-item">
                  <a className={window.location.href.split('/')[3] == "our-experts" ? "nav-link active" : "nav-link"} href="/our-experts">Our Expertise</a>
                </li>
                <li className="nav-item">
                  <a className={window.location.href.split('/')[3] == "contact" ? "nav-link active" : "nav-link"} href="/contact">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>


      </>
    )
  }

}
export default Header