import React, { component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';

class Happy_Customer extends React.Component {
	
    render() {
	
        return (
            <>
			<style>
			{`
					
					.ohc
					{
					  border-radius:0px 25px 0px 25px; 
					  cursor:pointer;
					}
						  .our-hcs
						  {
							  border-radius:25px 0px 25px 0px;
						  }
						  .relative {
							position: relative;						
						  }					  
						  .hidden.absolute {
							position: absolute;
							display: inline-block;						
							left: 0;
							right: 0;
							margin: auto;
							cursor:pointer;
															
						  }
						  .absolute.one {
							position: absolute;
							display: inline-block;						
							left: 0;
							right: 0;
							margin: auto;
						    height:10em;						
							box-shadow: 0 0 10px #cdcdcd;
							cursor:pointer;						
                           
						  }						  
						  .hidden {
							opacity:0;
							
						  }
						  
						  .relative:hover .hidden {
							opacity: 1;
							margin: auto;
							transform: scale(1.15) translate(1%);	
							transition: transform .4s ease;
							height:22em;
							z-index: 1;
							
						  }
						  
						  .relative:hover > .one {						
							opacity:0;
						  }
						  .h-hc.desktop
						  {
							  height:15em;
						  }
						  .our-happy-customer .carousel-inner{
							overflow: visible;
						  }
						 
						  
            `}
			</style>
            <div className="hm_testimonial_wrapper our-hc our-happy-customer">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<h1 className="hm_heading hm_heading_01 happy"><span className="hm_color">Our</span> happy customers</h1>
                           
						</div>
					</div>
					<div className="row">
                    <div className='hc h-hc desktop'>
                     <div className='carousel-inner'>
                     <div className="col-lg-4 col-md-4 ml-5">
								<div className="hm_testi_box relative">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer1" className="img-fluid user" />
									</div> */}
									
									
								
								 
									<div className="card shadow our-hcs  hidden absolute">
									<div className="card-body">
									<h2>Roli Bhalla</h2>
											<p>The  work at BEE YOU EXPORTS is fantastic!
																Thanks to you, my entire wardrobe has an entirely new look. Also, the way I dress has changed drastically, and it suits my age perfectly.
																I cannot express how drastically your wardrobe has optimised my way of living.
																<br/>Thank you so much!</p></div>
									
									</div>
												
										
								<div className="card shadow our-hcs absolute one">
									<div className="card-body">
									<h2>Roli Bhalla</h2>
									<p variant="secondary">The  work at BEE YOU EXPORTS is fantastic!
																	Thanks to you,......</p>
									</div>
									
									</div>
									
								</div>
							</div>
							<div className="col-lg-4 col-md-4">
								<div className="hm_testi_box relative">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer2" className="img-fluid user" />
									</div> */}
								
									<div className="card shadow our-hcs  hidden absolute">
									<div className="card-body">
									<h2>Manju Tyagi</h2>
									<p>I have never come across a HOUSE OF FASHION,<br/>so prompt and tidy in terms of quality and delivery. The detailing of the designs is just overwhelming. I must say, the craftsmen who worked so hard to etch these designs and  owners deserve all the credit in the world. Needless to say, I am more than happy to be in the hands of such skilled professionals.
What else could one ask for?<br/>! Keep up the great work!</p></div>
									
									</div>
												
										
								<div className="card shadow our-hcs absolute one">
									<div className="card-body">
									<h2>Manju Tyagi</h2>
									<p variant="secondary">I have never come across a HOUSE OF FASHION,<br/>so prompt...</p>
									</div>
									
									</div>
								</div>
							</div>
                            <div className="col-lg-4 col-md-4">
								<div className="hm_testi_box relative">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer3" className="img-fluid user" />
									</div> */}
									
									<div className="card shadow our-hcs  hidden absolute">
									<div className="card-body">
									<h2>Akansha Singh</h2>
											<p>Prompt service, efficient delivery, fantastic creativity and immaculate craftsmanship!</p></div>
									
									</div>
												
										
								<div className="card shadow our-hcs absolute one">
									<div className="card-body">
									<h2>Akansha Singh</h2>
									<p variant="secondary">Prompt service, efficient delivery, fantastic creativity....</p>
									</div>
									
									</div>
								
								
								</div>
							</div>
                     </div>
                      {/* <Carousel.Item>
                      <div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									<div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer1" className="img-fluid user" />
									</div> 
									<h2>Lorem Ipsum</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of cla ssical Latin literature from 45 BC, making it over 2000 years old. </p>
								</div>
							</div>
							<div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									<div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer2" className="img-fluid user" />
									</div> 
									<h2>Lorem Ipsum</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of cla ssical Latin literature from 45 BC, making it over 2000 years old. </p>
								</div>
							</div>
                            <div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									<div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer3" className="img-fluid user" />
									</div> 
									<h2>Lorem Ipsum</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of cla ssical Latin literature from 45 BC, making it over 2000 years old. </p>
								</div>
							</div>
                       </Carousel.Item> */}
 
                      </div>
							{/*mobile view*/}
						{/*mobile view*/}
						<Carousel className='hc h-hc mobile'>
                     <Carousel.Item>
                     <div className="col-lg-4 col-md-4 ml-5">
								<div className="hm_testi_box">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer1" className="img-fluid user" />
									</div> */}
									<div className="card ohc">
										<div className="card-body">
									<h2>Roli Bhalla</h2>
									<p>The  work at BEE YOU EXPORTS is fantastic!
																Thanks to you, my entire wardrobe has an entirely new look. Also, the way I dress has changed drastically, and it suits my age perfectly.
																I cannot express how drastically your wardrobe has optimised my way of living.
																<br/>Thank you so much!</p>
								</div>
							</div>
							</div>
									</div>
							</Carousel.Item>
							<Carousel.Item>
							<div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer2" className="img-fluid user" />
									</div> */}
										<div className="card ohc">
										<div className="card-body">
									<h2>Manju Tyagi</h2>
									<p>I have never come across a HOUSE OF FASHION,so prompt and tidy in terms of quality and delivery. The detailing of the designs is just overwhelming. I must say, the craftsmen who worked so hard to etch these designs and  owners deserve all the credit in the world. Needless to say, I am more than happy to be in the hands of such skilled professionals.
                                       What else could one ask for?! Keep up the great work!</p>
								</div>
							</div>
							</div>
							</div>
							</Carousel.Item>
							<Carousel.Item>
                            <div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer3" className="img-fluid user" />
									</div> */}
									<div className="card ohc">
										<div className="card-body">
									<h2>Akansha Singh</h2>
									<p>Prompt service, efficient delivery, fantastic creativity and immaculate craftsmanship!</p>
								</div>
							</div>
							</div>
							</div>
                     </Carousel.Item>
                      {/* <Carousel.Item>
                      <div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
								 <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer1" className="img-fluid user" />
									</div> 
									<h2>Lorem Ipsum</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of cla ssical Latin literature from 45 BC, making it over 2000 years old. </p>
								</div>
							</div>
							</Carousel.Item>
							<Carousel.Item>
							<div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									<div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer2" className="img-fluid user" />
									</div> 
									<h2>Lorem Ipsum</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of cla ssical Latin literature from 45 BC, making it over 2000 years old. </p>
								</div>
							</div>
							</Carousel.Item>
							<Carousel.Item>					
							
							
                            <div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									<div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer3" className="img-fluid user" />
									</div> 
									<h2>Lorem Ipsum</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of cla ssical Latin literature from 45 BC, making it over 2000 years old. </p>
								</div>
							</div>
                       </Carousel.Item> */}
 
                      </Carousel>
					  {/* tab */}
					 
							
							<Carousel className='hc h-hc tab'>
                     <Carousel.Item>
                     <div className="col-lg-4 col-md-4 ml-5">
								<div className="hm_testi_box">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer1" className="img-fluid user" />
									</div> */}
									<div className="card ohc">
										<div className="card-body">
									<h2>Roli Bhalla</h2>
									<p>The  work at BEE YOU EXPORTS is fantastic!
																Thanks to you, my entire wardrobe has an entirely new look. Also, the way I dress has changed drastically, and it suits my age perfectly.
																I cannot express how drastically your wardrobe has optimised my way of living.
																<br/>Thank you so much!</p>
								</div>
							</div>
							</div>
									</div>
							
							<div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer2" className="img-fluid user" />
									</div> */}
										<div className="card ohc">
										<div className="card-body">
									<h2>Manju Tyagi</h2>
									<p>I have never come across a HOUSE OF FASHION,so prompt and tidy in terms of quality and delivery. The detailing of the designs is just overwhelming. I must say, the craftsmen who worked so hard to etch these designs and  owners deserve all the credit in the world. Needless to say, I am more than happy to be in the hands of such skilled professionals.
                                       What else could one ask for?! Keep up the great work!</p>
								</div>
							</div>
							</div>
							</div>
							
                            <div className="col-lg-4 col-md-4">
								<div className="hm_testi_box">
									{/* <div className="hm_test_img">
										<img src="assets/images/buexports/index1.png" alt="handmade-craft-acustomer3" className="img-fluid user" />
									</div> */}
									<div className="card ohc">
										<div className="card-body">
									<h2>Akansha Singh</h2>
									<p>Prompt service, efficient delivery, fantastic creativity and immaculate craftsmanship!</p>
								</div>
							</div>
							</div>
							</div>
                     </Carousel.Item>
					 </Carousel>		
							
						
					</div>
				</div>
			</div>
             </>
        )
    }

}
export default Happy_Customer