import React from "react";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";

import Header from "./pages/header";
import Footer from "./pages/footer";
import Home from "./pages/home";
import About_Us from "./pages/about-us";
import Our_Experts from "./pages/our-experts";
import Products from "./pages/products";
import Contact from "./pages/contact";
import Happy_Customer from "./pages/happy-customer";





const App = () => {

    return (
    
      <>
     
    <Header/>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about-us" element={<About_Us/>} />
          <Route path="/our-experts" element={<Our_Experts/>} />
          <Route path="/products" element={<Products/>} />
          <Route path="/contact" element={<Contact/>} />
          </Routes> 
        </BrowserRouter>
        <Footer/>
        
     
      </>
    );

};
export default App;
