import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Happy_Customer from "./happy-customer";

class Our_Experts extends React.Component {
    render() {
        return (
            <>
                <style>
                    {`
                        
                        .para-main
                       {
                    
                           font-size:16px;
                           letter-spacing:1px;
                           text-align:justify;
                          
                       }
                      .col-md-5 .expert
                       {
                        min-height: 19em;
                    
                       }
                       .expert
			{
				margin-left:8px;
				border-radius:2rem;
				min-height:23em;
			}
                       
                   
                        
                            `}
                </style>
                {/* <!--banner start--> */}
                <div className="hm_bread_wrapper">
                    <div className="container">
                        <div className="row" style={{ marginTop: "58px" }}>
                            <div className="col-lg-12 col-md-12">
                                <h1>Our Expertise</h1>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--About section start--> */}
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5">
                            <div className="card  shadow expert">
                                <div className="card-body">
                                    <img className="img-fluid" src="assets/images/buexports/uploads/2.jpg" alt="" height={350} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">

                            <p className="para-main">
                                Zardozi is the art of weaving heavy, elaborate metal embroidery on net, georgette, organza, silk, satin, or velvet
                                fabrics. The designs are created using gold and silver threads and often incorporate pearls, beads, and other precious
                                stones, resulting in a beautiful piece of clothing populated by various pearls, stones, dabka thread and beads.
                            </p>
                            <p className="para-main">
                                Through efforts focused on conceptualizing unique stitch patterns, we at BEE YOU ensure 80-100 fresh ingenious
                                designs for our patrons each season. Consequently, our portfolio consists of multiple timeless and vibrant handembroidered articles, ranging from traditional Awadhi to Chikankari, Zardozi, Gota-Patti and other handmade
                                embroideries.
                            </p>

                        </div>
                        <div className="col-md-1"></div>
                    </div>


                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 ">

                            <p className="para-main mt-4">

                                As an ever-evolving fashion house, our forte lies in understanding our clients’ needs and offering creative and bold
                                designs that suit their tastes. We also strive to assist the traditional artisan community by creating jobs and offering
                                them a cultivated work environment. Our artisans are specialists who have been handed down the skill of authentic,
                                handmade Chikankari by their predecessors. They are incredibly adept at etching intricate embroidered patterns on a
                                variety of fabrics, such as cotton, muslin, georgette etc.
                            </p>
                        </div>

                        <div className="col-md-5">
                            <div className="card shadow mt-4 expert">
                                <div className="card-body">
                                    <img className="img-fluid " src="assets/images/buexports/uploads/3.jpg" alt="" height={350} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>

                </div>
                <br /><br />
                {/* <div className="hm_artist_wrapper hm_arrivals_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="hm_heading hm_heading_01"><span className="hm_color">Our</span> expert artists</h1>
                        </div>
                    </div>
                    <div className="row">
                      
                            <div className="col-lg-6 col-md-6">
                                <div className="hm_artist_box">
                                    <div className="artist_img">
                                        <img src="assets/images/buexports/usericon.jpg" alt="handmade-craft-artist1" className="img-fluid artist"/>
                                    </div>
                                    <div className="artist_data">
                                        <h3>Lorem Ipsum</h3>
                                        <p className="artist_post">Chief Advisior</p>
                                        <p className="artist_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tempus erat, et luctus quam. Maecenas cursus porta tortor, vel consectetur ante volutpat imperdiet. </p>
                                        <ul className="artist_social">
                                            <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li>
                                            <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hm_artist_box">
                                    <div className="artist_img">
                                        <img src="assets/images/buexports/usericon1.jpg" alt="handmade-craft-artist2" className="img-fluid artist" />
                                    </div>
                                    <div className="artist_data">
                                        <h3>Lorem Ipsum</h3>
                                        <p className="artist_post">Chief Advisior</p>
                                        <p className="artist_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tempus erat, et luctus quam. Maecenas cursus porta tortor, vel consectetur ante volutpat imperdiet. </p>
                                        <ul className="artist_social">
                                            <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li>
                                            <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hm_artist_box artist_right_image">
                                    <div className="artist_data">
                                        <h3>Lorem Ipsum</h3>
                                        <p className="artist_post">Chief Advisior</p>
                                        <p className="artist_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tempus erat, et luctus quam. Maecenas cursus porta tortor, vel consectetur ante volutpat imperdiet. </p>
                                        <ul className="artist_social">
                                            <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li>
                                            <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                    <div className="artist_img">
                                        <img src="assets/images/buexports/usericon2.png" alt="handmade-craft-artist3" className="img-fluid artist" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="hm_artist_box artist_right_image">
                                    <div className="artist_data">
                                        <h3>Lorem Ipsum</h3>
                                        <p className="artist_post">Chief Advisior</p>
                                        <p className="artist_para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et tempus erat, et luctus quam. Maecenas cursus porta tortor, vel consectetur ante volutpat imperdiet. </p>
                                        <ul className="artist_social">
                                            <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                            </li>
                                            <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                    <div className="artist_img">
                                        <img src="assets/images/buexports/usericon.jpg" alt="handmade-craft-artist4" className="img-fluid artist" />
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                </div>
            </div> */}
                {/* <div className="hm_service_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="hm_service_box">
                            <div className="service_icon">
                                <i className="flaticon flaticon-quality"></i>
                            </div>
                            <h2>Quality Work</h2>
                            <p> Omnicos directe desirabilite nov lits es lingua On refus continuar payar custos traductores solmen pronunciation.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="hm_service_box">
                            <div className="service_icon">
                                <i className="flaticon flaticon-delivery-truck-with-packages-behind"></i>
                            </div>
                            <h2>Free Delivery</h2>
                            <p> Omnicos directe desirabilite nov lits es lingua On refus continuar payar custos traductores solmen pronunciation.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="hm_service_box">
                            <div className="service_icon">
                                <i className="flaticon flaticon-24-hours-delivery"></i>
                            </div>
                            <h2>24 Hour Support</h2>
                            <p> Omnicos directe desirabilite nov lits es lingua On refus continuar payar custos traductores solmen pronunciation.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="hm_service_box">
                            <div className="service_icon">
                                <i className="flaticon flaticon-money-bag-with-dollar-symbol"></i>
                            </div>
                            <h2>100% Money Back</h2>
                            <p> Omnicos directe desirabilite nov lits es lingua On refus continuar payar custos traductores solmen pronunciation.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="hm_service_box">
                            <div className="service_icon">
                                <i className="flaticon flaticon-giftbox"></i>
                            </div>
                            <h2>Packaging</h2>
                            <p> Omnicos directe desirabilite nov lits es lingua On refus continuar payar custos traductores solmen pronunciation.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="hm_service_box">
                            <div className="service_icon">
                                <i className="flaticon flaticon-delivery-truck-with-circular-clock"></i>
                            </div>
                            <h2>Fast Delivery</h2>
                            <p> Omnicos directe desirabilite nov lits es lingua On refus continuar payar custos traductores solmen pronunciation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
                {/* <!--Choose section start--> */}
                {/* <div className="hm_choose_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        
                    </div>
                   <div className="col-lg-8 col-md-12 pull-right padding_left">
                        <div className="hm_choose_data">
                            <h1 className="hm_heading text-left"><span className="hm_color">why </span>Choose Us</h1>
                            <div className="hm_choose_list">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
       <h4>Respecting Your Time</h4>
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
       <h4>Latest In Technology</h4>
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      <h4>Professional Staff</h4>
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
     <h4>Free Home Dilevery</h4>
      </button>
    </h2>
    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  {/* <!--  --> 
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      <h4>High Quality</h4>
      </button>
    </h2>
    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
</div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
                {/* <!--our partners section start--> */}
                {/* <div className="hm_partners_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <h1 className="hm_heading">Our clients</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="hm_partners_slider">
                        <Carousel>
                     <Carousel.Item>
                                    <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                     </Carousel.Item>
                      <Carousel.Item>
                                   <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                       </Carousel.Item>
 
                      </Carousel>
                               
                        </div>
                    </div>
                </div>
            </div>
        </div>
          
           */}
                {/* our happy customers */}
                <Happy_Customer />

            </>


        )
    }
}
export default Our_Experts