import React, { component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Happy_Customer from './happy-customer';





class Home extends React.Component {
    render() {

        return (
            <>
            
                <style>
                    {`
                    .artist
                    {
                        height:238px;
                    }
                   
                  
                     `}
                     </style>
                     {/* <!--banner start--> */}
                     <div className="hm_banner_wrapper">
                <div className="">
                <Carousel className='banner'>
                     <Carousel.Item>
                     <div className="item">
                        <div className="slider_bg" style={{backgroundImage:"url('assets/images/buexports/uploads/landing_1.jpg')"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-xs-12 col-lg-offset-2 col-md-offset-2">
                                        <div className="slider_caption">
                                            <h3 className="animate__animated animate__slideInDown">we make things with love</h3>
                                            <h1 className='pt-lg-5 pb-lg-5 mt-lg-5 bh'>bee you exports</h1>
                                            {/* <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br/> Lorem Ipsum has been the industry's standard dummy text ever since the <br/> 1500s, when an unknown printer took a galley.
                                            </p>
                                            <div className="button_wrapper">
                                                <a href="#" className="read_more hm_btn">read more</a>
                                            </div> */}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                     </Carousel.Item>
                      <Carousel.Item>
                      <div className="item">
                        <div className="slider_bg" style={{backgroundImage:"url('assets/images/buexports/uploads/landing_2.jpg')"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-xs-12 col-lg-offset-2 col-md-offset-2">
                                        <div className="slider_caption">
                                        <h3>we make things with love</h3>
                                        <h1 className='pt-lg-5 pb-lg-5 mt-lg-5 bh'>bee you exports</h1>
                                            {/* <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br/> Lorem Ipsum has been the industry's standard dummy text ever since the <br/> 1500s, when an unknown printer took a galley.
                                            </p>
                                            <div className="button_wrapper">
                                                <a href="#" className="read_more hm_btn">read more</a>
                                            </div> */}
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                       </Carousel.Item>
                       {/* <Carousel.Item>
                      <div className="item">
                        <div className="slider_bg" style={{backgroundImage:"url('assets/images/buexports/uploads/3.jfif')"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-xs-12 col-lg-offset-2 col-md-offset-2">
                                        <div className="slider_caption">
                                        <h3>we make things with love</h3>
                                        <h1 className=' pt-lg-5 pb-lg-5 mt-lg-5 bh'>bee you exports</h1> */}
                                            {/* <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br/> Lorem Ipsum has been the industry's standard dummy text ever since the <br/> 1500s, when an unknown printer took a galley.
                                            </p>
                                            <div className="button_wrapper">
                                                <a href="#" className="read_more hm_btn">read more</a>
                                            </div> */}
                                            
                                        {/* </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>              
                       </Carousel.Item> */}
 
                      </Carousel>
                    
                </div>
            </div>
            {/* <!--About section start--> */}
            <div className="hm_about_wrapper">
           
                <div className="container mw">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_about_img">
                                <img src="assets/images/buexports/uploads/home_about.jpg" alt="handmade-craft-about" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_about_data">
                                <h1><span className="hm_color">Welcome</span> To Bee you Exports</h1>
                                <p>Bee You Exports aims to revitalise the sartorial legacy of Lucknow’s Zardozi craftsmanship. The magic our forefathers weaved on the canvas of precious silks and fabrics still resides on some fingertips. We have an army of such artisans at our disposal, who have inherited the craft and practice it to weave the most intricate patterns available to man. Our endeavour is not just to offer the art the showcase it deserves but also to elevate the living standards of these skilled artisans.</p>
                              
                                <a href="/about-us" className="hm_btn">read more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--New Arrivals section start--> */}
            {/* <div className="hm_arrivals_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="hm_heading hm_heading_01"><span className="hm_color">Our</span> New Arrivals</h1>
                        </div>
                    </div>
                    <div className="row">
                     
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="new_pro_box">
                                    <div className="new_pro_img">
                                        <img src="assets/images/buexports/1.jpeg" alt="handmade-craft-arrivals1" className="img-fluid" />
                                    </div>
                                    <div className="new_pro_data">
                                        <h3><a href="shop-single.html">Jellied eels.</a></h3>
                                        <p>orem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        
                                        
                                    </div>
                                </div>
                            </div> */}
                            {/* <!--2--> */}
                            {/* <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="new_pro_box">
                                    <div className="new_pro_data">
                                        <h3><a href="shop-single.html">Deep fried pomfret.</a></h3>
                                        <p>orem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                       
                                       
                                    </div>
                                    <div className="new_pro_img">
                                        <img src="assets/images/buexports/3.jpeg" alt="handmade-craft-arrivals2" className="img-fluid" />
                                    </div>
                                </div>
                            </div> */}
                            {/* <!--3--> */}
                            {/* <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="new_pro_box">
                                    <div className="new_pro_img">
                                        <img src="assets/images/buexports/4.jpeg" alt="handmade-craft-arrivals3" className="img-fluid" />
                                    </div>
                                    <div className="new_pro_data">
                                        <h3><a href="shop-single.html">Marinated swordfish</a></h3>
                                        <p>orem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                          
                                        
                                    </div>
                                </div>
                            </div> */}
                            {/* <!--4--> */}
                            {/* <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="new_pro_box">
                                    <div className="new_pro_img">
                                        <img src="assets/images/buexports/5.jpeg" alt="handmade-craft-arrivals4" className="img-fluid" />
                                    </div>
                                    <div className="new_pro_data">
                                        <h3><a href="shop-single.html">Jellied</a></h3>
                                        <p>orem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        
                                        
                                    </div>
                                </div>
                            </div> */}
                            {/* <!--5--> */}
                            {/* <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="new_pro_box">
                                    <div className="new_pro_data">
                                        <h3><a href="shop-single.html">Salmon</a></h3>
                                        <p>orem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                       
                                       
                                    </div>
                                    <div className="new_pro_img">
                                        <img src="assets/images/buexports/6.jpeg" alt="handmade-craft-arrivals5" className="img-fluid" />
                                    </div>
                                </div>
                            </div> */}
                            {/* <!--6--> */}
                            {/* <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="new_pro_box">
                                    <div className="new_pro_img">
                                        <img src="assets/images/buexports/7.jpeg" alt="handmade-craft-arrivals6" className="img-fluid" />
                                    </div>
                                    <div className="new_pro_data">
                                        <h3><a href="shop-single.html">Salmon roe.</a></h3>
                                        <p>orem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                        
                                      
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </div> */}
            {/* <!--Choose section start--> */}
            {/* <div className="hm_choose_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            
                        </div>
                        <div className="col-lg-4 col-md-12">
                            
                        </div>
                        <div className="col-lg-8 col-md-12 pull-right padding_left">
                        <div className="hm_choose_data">
                            <h1 className="hm_heading text-left"><span className="hm_color">why </span>Choose Us</h1>
                            <div className="hm_choose_list">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed show" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne show">
       <h4>Respecting Your Time</h4>
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
       <h4>Latest In Technology</h4>
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      <h4>Professional Staff</h4>
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
     <h4>Free Home Dilevery</h4>
      </button>
    </h2>
    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
  
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      <h4>High Quality</h4>
      </button>
    </h2>
    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">

      <div className="accordion-body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</div>
    </div>
  </div>
</div>
                               
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div> */}
            {/* <!--our artist section start--> */}
            
            {/* <!--happy customer sevtion start--> */}
			<Happy_Customer/>
            {/* <!--our blog section start--> */}
            {/* <div className="hm_news_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="hm_heading hm_heading_01"><span className="hm_color">Our </span>latest blog</h1>
                        </div>
                    </div>
                    <div className="row">
                       
                            <div className="col-lg-4 col-md-12">
                                <div className="hm_news_box">
                                    <div className="hm_news_img">
                                        <img src="assets/images/new_pro1.jpg" alt="handmade-craft-blog1" className="img-fluid"/>
                                        <div className="news_img_overlay"></div>
                                        <div className="overlay_buttons">
                                            <a className="popup-iframe" href="https://www.youtube.com/watch?v=KAQjLTjW-dM"><i className="fa fa-play-circle-o" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    <div className="hm_news_data">
                                        <h3><a href="blog-single.html">Inteligent Transitions In Design</a></h3>
                                        <ul>
                                            <li><a href="#"><i className="fa fa-user" aria-hidden="true"></i> admin</a></li>
                                            <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i> 12 june 2021</a></li>
                                            <li><a href="#"><i className="fa fa-tags" aria-hidden="true"></i>picture</a></li>
                                        </ul>
                                        <p>Pleasure and praising pain was born and will give you a complete account of the systems, and expound the actual teaching.</p>
                                        <a href="#" className="news_btn">read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12">
                                <div className="hm_news_big_post">
                                    <img src="assets/images/blog_big.jpg" alt="handmade-craft-blog" className="img-fluid" />
                                    <div className="hm_blog_overlay_data">
                                        <div className="news_data">
                                            <span className="hm_blog_date">13 june 2021</span>
                                            <h2><a href="blog-single.html">standard blog post</a></h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et do lore magna aliqua.Lonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                            <div className="blog_admin">Posted by <span className="hm_color">william harry</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="hm_news_box">
                                    <div className="hm_news_img">
                                        <img src="assets/images/new_pro2.jpg" alt="handmade-craft-blog2" className="img-fluid"/>
                                        <div className="news_img_overlay"></div>
                                        <div className="overlay_buttons">
                                            <a className="popup-news-gallery" href="assets/images/gallery_big2.jpg"><i className="fa fa-search" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    <div className="hm_news_data">
                                        <h3><a href="blog-single.html">DIY Matisse</a></h3>
                                        <ul>
                                            <li><a href="#"><i className="fa fa-user" aria-hidden="true"></i> admin</a></li>
                                            <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i> 12 june 2021</a></li>
                                            <li><a href="#"><i className="fa fa-tags" aria-hidden="true"></i>picture</a></li>
                                        </ul>
                                        <p>Pleasure and praising pain was born and will give you a complete account of the systems, and expound the actual teaching.</p>
                                        <a href="#" className="news_btn">read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="hm_news_box">
                                    <div className="hm_news_img">
                                        <img src="assets/images/new_pro3.jpg" alt="handmade-craft-blog3" className="img-fluid"/>
                                        <div className="news_img_overlay"></div>
                                        <div className="overlay_buttons">
                                            <a className="popup-news-gallery" href="assets/images/gallery_big9.jpg"><i className="fa fa-search" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    <div className="hm_news_data">
                                        <h3><a href="blog-single.html">Creating Art Spaces For Kids</a></h3>
                                        <ul>
                                            <li><a href="#"><i className="fa fa-user" aria-hidden="true"></i> admin</a></li>
                                            <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i> 12 june 2021</a></li>
                                            <li><a href="#"><i className="fa fa-tags" aria-hidden="true"></i>picture</a></li>
                                        </ul>
                                        <p>Pleasure and praising pain was born and will give you a complete account of the systems, and expound the actual teaching.</p>
                                        <a href="#" className="news_btn">read more</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="hm_news_box">
                                    <div className="hm_news_img">
                                        <img src="assets/images/new_pro4.jpg" alt="handmade-craft-blog4" className="img-fluid"/>
                                        <div className="news_img_overlay"></div>
                                        <div className="overlay_buttons">
                                            <a className="popup-news-gallery" href="assets/images/gallery_big10.jpg"><i className="fa fa-search" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    <div className="hm_news_data">
                                        <h3><a href="blog-single.html">Illuminated Accessories</a></h3>
                                        <ul>
                                            <li><a href="#"><i className="fa fa-user" aria-hidden="true"></i> admin</a></li>
                                            <li><a href="#"><i className="fa fa-calendar" aria-hidden="true"></i> 12 june 2021</a></li>
                                            <li><a href="#"><i className="fa fa-tags" aria-hidden="true"></i>picture</a></li>
                                        </ul>
                                        <p>Pleasure and praising pain was born and will give you a complete account of the systems, and expound the actual teaching.</p>
                                        <a href="#" className="news_btn">read more</a>
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                </div>
            </div> */}
            {/* <!--our partners section start--> */}
            {/* <div className="hm_partners_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h1 className="hm_heading">Our clients</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="hm_partners_slider">
                            <Carousel>
                     <Carousel.Item>
                                    <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                     </Carousel.Item>
                      <Carousel.Item>
                                   <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                       </Carousel.Item>
 
                      </Carousel>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                            
                    </>
        )
    }

}
export default Home