import React from'react';
import Carousel from 'react-bootstrap/Carousel';
import Happy_Customer from './happy-customer';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

const images = [
  'assets/images/buexports/uploads/1.jpg',
  'assets/images/buexports/uploads/2.jpg',
  'assets/images/buexports/uploads/3.jpg',
  'assets/images/buexports/uploads/4.jpg',
  'assets/images/buexports/uploads/5.jpg',
  'assets/images/buexports/uploads/6.jpg',
  'assets/images/buexports/uploads/7.jpg',
  'assets/images/buexports/uploads/8.jpg',
  'assets/images/buexports/uploads/9.jpg',
  'assets/images/buexports/uploads/10.jpg',
  'assets/images/buexports/uploads/11.jpg',
  'assets/images/buexports/uploads/12.1.jpg',
  'assets/images/buexports/uploads/13.1.jpg',
  'assets/images/buexports/uploads/14.2.jpg',
  'assets/images/buexports/uploads/15.2.jpg', 
  'assets/images/buexports/1.jpeg',  
  'assets/images/buexports/3.jpeg',
  'assets/images/buexports/4.jpeg',
  'assets/images/buexports/5.jpeg',
 
];


class Products extends React.Component
{
    
    constructor(props) {
        super(props);
    
        this.state = {
          photoIndex:0,
          isOpen: false,
        };
      } 
        
        render()

        {
            const { photoIndex, isOpen } = this.state;

            return(


                        <>
                       <style>
                            {`
                        
                        .ReactModal__Overlay
                        {
                            margin-top: 6em;
                        }
                        
                            `}
                            </style>

<div className="hm_bread_wrapper">
            <div className="container">
                <div className="row" style={{marginTop:"58px"}}>
                    <div className="col-lg-12 col-md-12">
                        <h1>our products</h1>
                        
                    </div>
                </div>
            </div>
        </div>
      <div>
    
    
      </div>
    
        {/* <!--blog section start--> */}
        <div className="hm_news_wrapper">
            <div className="container">
                <div className="row">
                <div>
       

        {isOpen && (
          <Lightbox
            mainSrc={images[this.state.img_key]}
            nextSrc={images[(this.state.img_key + 1) % images.length]}
            prevSrc={images[(this.state.img_key + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                img_key: (this.state.img_key + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                img_key: (this.state.img_key + 1) % images.length,
              })
            }
          />
        )}
      </div>
     
     
                      
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                <img src="assets/images/buexports/uploads/1.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery"  onClick={() => this.setState({ isOpen: true ,img_key:0})}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/2.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:1 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/3.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:2  })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/4.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:3 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/5.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:4 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/6.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:5 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/7.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:6 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/8.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:7 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/9.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:8 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box" id='check'>
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/10.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-iframe" onClick={() => this.setState({ isOpen: true,img_key:9 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/uploads/11.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:10 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                <img src="assets/images/buexports/uploads/12.1.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery"  onClick={() => this.setState({ isOpen: true,img_key:11 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                <img src="assets/images/buexports/uploads/13.1.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery"  onClick={() => this.setState({ isOpen: true,img_key:12 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                <img src="assets/images/buexports/uploads/14.2.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery"  onClick={() => this.setState({ isOpen: true,img_key:13 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                <img src="assets/images/buexports/uploads/15.2.jpg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery"  onClick={() => this.setState({ isOpen: true,img_key:14 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                       
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box" id='check'>
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/1.jpeg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-iframe" onClick={() => this.setState({ isOpen: true,img_key:15 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>                                   
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/3.jpeg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:16 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/4.jpeg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:17 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="hm_news_box">
                                <div className="hm_news_img">
                                    <img src="assets/images/buexports/5.jpeg" alt="" className="img-responsive"/>
                                    <div className="news_img_overlay"></div>
                                    <div className="overlay_buttons">
                                        <a className="popup-news-gallery" onClick={() => this.setState({ isOpen: true,img_key:18 })}><i className="fa fa-search-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div className="hm_news_data">
                                    <h3><a href="#"></a></h3>
                                   
                                </div>
                            </div>
                        </div>
                      
                       
                </div>

                {/* <!--pagination start-->
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <ul className="hm_pagination">
                            <li><a href="#check" className="active">1</a></li>
                            <li><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                            <li><a href="#" className="next">Next</a></li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
        {/* <!--our partners section start--> */}
        {/* <div className="hm_partners_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <h1 className="hm_heading">Our clients</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="hm_partners_slider">
                        <Carousel>
                     <Carousel.Item>
                                    <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                     </Carousel.Item>
                      <Carousel.Item>
                                   <div className="item"><img src="assets/images/partner1.png" alt="handmade-craft-clients1"/></div>
                                    <div className="item"><img src="assets/images/partner2.png" alt="handmade-craft-clients2"/></div>
                                    <div className="item"><img src="assets/images/partner3.png" alt="handmade-craft-clients3"/></div>
                                    <div className="item"><img src="assets/images/partner4.png" alt="handmade-craft-clients4"/></div>
                                    <div className="item"><img src="assets/images/partner5.png" alt="handmade-craft-clients5"/></div>
                       </Carousel.Item>
 
                      </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        
          {/* <!--happy customer sevtion start--> */}
       <Happy_Customer/>

</>







    )
}

}
export default Products